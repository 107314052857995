// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/styles/main.css */

body {
  margin: 0;
  overflow: hidden;
}

canvas {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/styles/main.css"],"names":[],"mappings":"AAAA,wBAAwB;;AAExB;EACE,SAAS;EACT,gBAAgB;AAClB;;AAEA;EACE,cAAc;AAChB","sourcesContent":["/* src/styles/main.css */\n\nbody {\n  margin: 0;\n  overflow: hidden;\n}\n\ncanvas {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
